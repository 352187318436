.Speeds {
    background-color: slateblue;
    font-size: 0.6em;
    color: white;
    padding: 1px 0;
    border-radius: 10px;
    white-space: nowrap;

    @mixin item-base {
        border-radius: 10px;
        padding: 2px 4px;
        cursor: pointer;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .Speeds__header {
        @include item-base;

        padding: 3px 4px;
        font-weight: bold;
    }

    .Speeds__item {
        @include item-base;

        &:hover {
            background-color: darkslateblue;
        }

        .Speeds__example {
            font-weight: bold;
            padding: 0 4px;
        }

        .Speeds__item-checkmark {
            font-weight: bold;
            position: absolute;
            right: 2px;
        }
    }
}
