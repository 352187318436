.Time {
    position: relative;
    display: flex;
    justify-content: center;

    .Time__text-container {
        padding: 16px;

        .Time__text {
            color: #6182fb;
            font-size: 1.5em;
            margin: 0;
            cursor: pointer;
        }
    }

    .Time__format-tip {
        position: absolute;
        top: -15px;
        left: 0;
        right: 0;
        text-align: center;
        font-size: 1.1em;
    }
}
