.App {
    height: 100%;
}

.note {
    color: black;
    font-weight: 300;
    float: right;
    font-size: .7em;
    margin-inline: 8px;
}